



























































































































































































/* PS Changes to the style also needs to be updated in pdf.scss for print */

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
}

.pdf_page {
  page-break-before: always;
  overflow: hidden;
  height: 100%;
  font-size: 12px;
}

.page-break {
  page-break-after: always;
}

.no-page-break {
  page-break-inside: avoid;
}
.red{
  color: #E73F33;
}

h1 {
  margin-top: 0;
}

h3,
h4 {
  margin: 0.75rem 0 0.25rem 0;
}

.row {
  display: flex;
  margin: 0.5rem;
}

.box {
  display: flex;
  flex-direction: column;
  padding: 0 2rem 0 0.5rem;
  line-height: 1.2rem;
}

.logo {
  width: 100%;
  height: auto;
}

.v-hidden {
  visibility: hidden;
}

.specificationDot {
  padding-right: 0.5rem;
  height: 0.8rem;
  vertical-align: top;
}

.label {
  color: #979797;
  font-style: italic;
  font-size: 10px;
}
span.content {
  min-height: 1.2rem; /* Show even empty spans */
}

.bold {
  font-weight: bold;
}
.checked{
  color: #c1c1c1;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  background-color: #00723F;
  border: solid 1px black;
  margin: 0.3rem;
}
.not_checked{
  color: #c1c1c1;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  background-color: #fff;
  border: solid 1px black;
  margin: 0.3rem;
}
.checked-checkbox{
  color: #c1c1c1;
  height: 0.5rem;
  width: 0.5rem;
  background-color: #00723F;
  border: solid 1px black;
  margin: 0.3rem;
}
.not_checked-checkbox{
  color: #c1c1c1;
  height: 0.5rem;
  width: 0.5rem;
  background-color: #fff;
  border: solid 1px black;
  margin: 0.3rem;
}
.label_radio{
  font-size: 0.8rem;
  font-style: italic;
  margin-bottom: 0.25rem;
  font-weight: 300;

}
.inline{
  display: inline !important;
}
