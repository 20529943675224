@import "./node_modules/@verified/veform/themes/default/style.scss";

$color-primary: #E73F33;
$color-secondary: #000;
$color-validated: rgb(157, 150, 150);

@font-face {
  font-family: 'TwCENmt';
  src: url('../page/futura_medium_bt-webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
}
body{
  font-family: 'TwCENmt';
}

.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.loader-background {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.5;
  background: white;
}

#preview-button {
  background-color: $color-primary;
  border: unset;
  padding: 0.8rem;
  color: white;
}

button {
  &:focus {
    outline: unset;
  }
}

.position-preview {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.buttons button {
  margin: 1rem;
  margin-bottom: 0;
  position: sticky;
  color: white;
}

.upload-file {
  margin: 1rem 0 0 0;
}

.strong {
  font-weight: 600;
}

.remove-file {
  font-style: normal;
  font-size: 1rem;
  padding: 0 0.5rem;

  i:hover {
    transform: scale(1.5);
    color: darkred;
    cursor: pointer;
  }
}

.logo {
  width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-primary;
  padding-top: 0.3rem;
}
h2{
  font-weight: 550;
  color: $color-secondary;
}

h5.sectionTitle {
  font-size: 1.2rem;
  padding-top: 1rem;
}

.header {
  background-color: $color-primary !important;
}

.smooth,
.smooth * {
  transition: all 0.5s ease, background-position 0s ease;

  &.delay,
  & .delay {
    transition-delay: 0.5s;
  }
}

body,
pre {
  color: #435363;
  font-variant-ligatures: common-ligatures;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

pre {
  white-space: pre-wrap;
  line-height: 1.75rem;
  font-size: 0.875rem;
}

input[readonly="readonly"] {
  cursor: default;
  background-color: #fbfbfb !important;
}

label {
  margin-bottom: 0.25rem;
}

hr {
  width: 100%;
}

.custom-radio {
  margin-bottom: 0.5rem !important;
}
.row{
  margin: 0 0 1rem 0;
}
.row.space-around {
  justify-content: space-around;
}

.top-heading {
  overflow: auto;

  & img {
    width: 35%;
    min-width: 10rem;
    margin-bottom: 1rem;
    float: right;
  }

  & div {
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 600;
    margin-right: 1rem;
  }
}

.btn-minus {
  padding: 0px 6px 4px 6px;
  font-size: 23px;
  border: 1px solid $color-primary;
  background-color: $color-primary;
  user-select: none;

  &:hover {
    border-color: $color-secondary;
    background-color: $color-secondary;
  }
}

.btn-plus {
  padding: 1px 4px 2px 4px;
  font-size: 20px;
  border: 1px solid $color-primary;
  background-color: $color-primary;
  user-select: none;

  &:hover {
    border-color: $color-secondary;
    background-color: $color-secondary;
  }
}

.btn-plus-gray,
.btn-minus-gray {
  border-color: #c1c1c1 !important;
}

.tooltip-right {
  cursor: default !important;
  padding: 0 0 0 1px;
  color: $color-primary;
  border-color: $color-primary;

  &:hover {
    background-color: $color-secondary;
    border-color: $color-secondary;
  }
}

.tooltip-inner {
  white-space: pre-wrap;
  background-color: $color-primary !important;
}

.bs-tooltip-top .arrow:before {
  border-top-color: $color-primary !important;
}

.upload-single {
  position: relative;
  border: 1px dashed $color-primary;
  background-color: $color-primary;
  text-align: center;
  line-height: 0.875rem;
  user-select: none;
  cursor: pointer;

  &:hover,
  &:focus {
    border-color: $color-secondary;
    background-color: $color-secondary;
  }

  &.selected {
    border-style: solid;
    padding-right: 1.75rem;

    & .remove {
      opacity: 1;
      pointer-events: inherit;
    }
  }

  & .remove {
    position: absolute;
    top: 7px;
    right: 7px;
    color: $color-primary;
    font-size: 22px;
    opacity: 0;
    pointer-events: none;

    &:hover,
    &:focus {
      color: $color-secondary;
    }
  }
}

.was-validated input[type="file"] {
  &:invalid ~ .upload-single {
    border-color: #b62537;
    color: #b62537;

    & ~ .invalid-feedback {
      display: block;
    }
  }

  &:valid ~ .upload-single {
    border-color: $color-validated;
  }
}

.v-select.disabled .v-select-toggle {
  border-color: #ccad894f;
  opacity: 1;
  cursor: default;
}

.landing {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.display-block {
  display: block;
}

.labelRequired .custom-control.custom-checkbox::after,
.info.labelRequired::after {
  padding-left: 3px;
  font-weight: bold;
  content: "*";
  color: #d11c31;
}

.info {
  font-size: 1rem;
  padding-top: 0.5rem;
  color: #6b6b6b;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.flex-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.italic {
  font-style: italic;
}

.btn-primary {
  background-color: $color-primary;
  border-color: $color-primary;

  &:hover {
    background-color: $color-secondary;
    border-color: $color-secondary;
  }
}

.steps ul li.current span {
  color: $color-primary;
  border-color: $color-primary;
}

.steps ul li.complete span {
  background-color: $color-primary;
  border-color: $color-primary;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $color-primary;
  border-color: $color-primary;
}

.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: $color-primary;
  border-color: $color-primary;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: $color-validated;
}

.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: $color-validated;
  border-color: $color-validated;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: $color-validated;
}

.v-dropdown-item.selected {
  background-color: $color-primary;
}

.custom-checkbox-label,
.custom-radio-label {
  width: 100%;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: $color-primary !important;
}

.v-select-toggle {
  font-size: 16px;
  padding: 6px 9px;
}

.section_title {
  font-weight: 700;
  font-size: 18px;
}

.field_title {
  padding: 0.3em 0 0 0;
  font-size: 15px;
}

.btn {
  padding: 6px 20px !important;
}

.display-table {
  display: table;
  height: 100%;
}

.display-table-cell-top {
  display: table-cell;
  vertical-align: top;
}

.display-table-cell-center {
  display: table-cell;
  vertical-align: center;
}

.display-table-cell-bottom {
  display: table-cell;
  vertical-align: bottom;
}

body {
  background: #ffffff !important;
}

#stepper {
  border: none;
}

.text_center {
  text-align: center;

  & input {
    text-align: center;
  }
}

.text_right {
  text-align: right;

  & input {
    text-align: right;
  }
}

.input-info {
  font-style: italic;
  color: $color-secondary;
  font-size: 0.8rem;
}
.container {
  margin-top: 2rem; text-align: left;
  // background-color: rgba(255,255,255,0.6);
}