<template>
  <div id="cover">
    <div id="vue-app" class="smooth">
      <div class="landing" :class="{ hidden: loading }">
        <div class="container py-4">
        
          <ve-col xs12 md3>
            <img class="mt-4 logo" :src="'./logo.png'" />
          </ve-col>
          <ve-col xs12 style=" text-align: center">
            <h2>{{ __("heading") }}</h2>
          </ve-col>

          <!-- FORM -->
          <form ref="form" v-if="!loading">
           <ve-card :headline="__('title')" v-if="role == 'owner'"> 
              <div class="row">
                <ve-col xs12 md6>
                  <ve-label required :label="__('ssn')"></ve-label>
                  <ve-input
                    v-model="ud.employee.ssn"
                    rules="pnrse|required"
                    mask="######-####"
                    placeholder="ÅÅMMDD-XXXX"
                    ref="personNumber"
                    blurvalidate
                  ></ve-input>
                </ve-col>
              </div>
              <div class="row">
                <ve-col xs12 md6>
                  <ve-label required :label="__('firstName')"></ve-label>
                  <ve-input
                    v-model="ud.employee.firstName"
                    rules="required"
                    blurvalidate
                  ></ve-input>
                </ve-col>
                <ve-col xs12 md6>
                  <ve-label required :label="__('lastName')"></ve-label>
                  <ve-input
                    v-model="ud.employee.lastName"
                    rules="required"
                    blurvalidate
                  ></ve-input>
                </ve-col>
              </div>
              <div class="row">
                <ve-col xs12 md12>
                  <ve-label required :label="__('address')"></ve-label>
                  <ve-input
                    v-model="ud.employee.address"
                    rules="required"
                    blurvalidate
                  ></ve-input>
                </ve-col>
              </div>
               <div class="row">
                <ve-col xs12 md6>
                  <ve-label required :label="__('zip')"></ve-label>
                  <ve-input
                    v-model="ud.employee.zip"
                    rules="required"
                    blurvalidate
                  ></ve-input>
                </ve-col>
                <ve-col xs12 md6>
                  <ve-label required :label="__('county')"></ve-label>
                  <ve-input
                    v-model="ud.employee.county"
                    rules="required"
                    blurvalidate
                  ></ve-input>
                </ve-col>
              </div>

              <div class="row">
                <ve-col xs12 md6>
                  <ve-label required :label="__('email')"></ve-label>
                  <ve-input
                    v-model="ud.employee.email"
                    rules="email"
                    blurvalidate
                  ></ve-input>
                </ve-col>
                <ve-col xs12 md6>
                  <ve-label required :label="__('phone')"></ve-label>
                  <ve-input
                    v-model="ud.employee.phone"
                    rules="phone"
                    blurvalidate
                  ></ve-input>
                </ve-col>
              </div>
            </ve-card>

            <ve-card :headline="__('title2')"  v-if="role == 'owner'">
              <div class="row">
                <ve-col xs12 md6>
                  <ve-label required :label="__('position')"></ve-label>
                  <ve-select
                    v-model="ud.selectedPosition"
                    :options="positions"
                    :searchable="true"
                    rules="required"
                    blurvalidate
                  ></ve-select>
                </ve-col>
              </div>
              <div class="row">
                <ve-col xs12 md3>
                  <ve-label required :label="__('employmentType')"></ve-label>
                  <ve-radio
                    v-model="ud.employment"
                    :options="{
                          		probationaryEmployment: { label: __('probationaryEmployment') },
                          		generalFixedEmployment: { label: __('generalFixedEmployment') },
                          		temporaryEmployment: { label: __('temporaryEmployment') },
                          		phdEmployment: { label: __('phdEmployment') },
                          		hourlyEmployment: { label: __('hourlyEmployment') },
                              permanentEmployment: { label: __('permanentEmployment') }
                          	}">
                  ></ve-radio>
                </ve-col>
                <ve-col xs12 md3>
                  <label class="field_title required">{{ __("dateFrom")}}</label>
                  <ve-datepicker
                    v-model="ud.dateFrom"
                    placeholder="Välj datum"
                    required
                  ></ve-datepicker>
                </ve-col>
                <ve-col xs12 md3>
                  <label class="field_title required">{{ __("dateTo")}}</label>
                  <ve-datepicker
                    v-model="ud.dateTo"
                    placeholder="Välj datum"
                    required
                  ></ve-datepicker>
                </ve-col>
              </div>
              <div class="row">
                <ve-col xs12 md6 v-if="ud.employment.generalFixedEmployment || ud.employment.temporaryEmployment">
                  <ve-label required :label="__('typeOfEmployment')"></ve-label>
                  <ve-input
                  v-if="ud.employment.generalFixedEmployment || ud.employment.temporaryEmployment"
                    v-model="ud.typeOfEmployment"
                    rules="required"
                    blurvalidate
                  ></ve-input>
                </ve-col>
                <ve-col xs12 md6 v-if="ud.employment.phdEmployment">
                  <ve-label :label="__('period')"></ve-label>
                  <ve-input
                  v-if="ud.employment.phdEmployment"
                    v-model="ud.period"
                    blurvalidate
                  ></ve-input>
                </ve-col>
              </div>
              <div class="row">
                <ve-col xs12 md4>
                  <ve-label required :label="__('manager')"></ve-label>
                  <ve-input
                    v-model="ud.manager"
                    rules="required"
                    blurvalidate
                  ></ve-input>
                </ve-col>
                <ve-col xs12 md4>
                  <ve-label required :label="__('costCenter')"></ve-label>
                  <ve-input
                    v-model="ud.costCenter"
                    rules="required"
                    blurvalidate
                  ></ve-input>
                </ve-col>
                <ve-col xs12 md4>
                  <ve-label :label="__('projectNumber')"></ve-label>
                  <ve-input
                    v-model="ud.projectNumber"
                  ></ve-input>
                </ve-col>
              </div>
            </ve-card>

            <ve-card :headline="__('title3')"  v-if="role == 'owner' && !ud.employment.hourlyEmployment">
              
                 <div class="row">
                <ve-col xs12 md4>
                  <ve-label required :label="__('employmentType')"></ve-label>
                  <ve-radio
                    v-model="ud.employmentRate"
                    inline
                    :options="{
                          		fullTime: { label: __('fullTime') },
                          		partTime: { label: __('partTime') }
                          	}">
                  ></ve-radio>
                </ve-col>
                <ve-col xs12 md6 v-if="ud.employmentRate.partTime">
                  <ve-label required :label="__('rate')"></ve-label>
                  <ve-input
                    v-model="ud.rate"
                    rules="required"
                    type="number"
                    blurvalidate
                    v-if="ud.employmentRate.partTime"
                  ></ve-input>
                </ve-col>
                <ve-col xs12 md3>
                  <ve-label required :label="__('workTime')"></ve-label>
                  <ve-radio
                    v-model="ud.workTime"
                    :options="{
                          		one: { label: __('1') },
                          		two: { label: __('2') }
                          	}">
                  ></ve-radio>
                </ve-col>
              </div>
              <div class="row">
              </div>
            </ve-card>
            <ve-card :headline="__('title4')"  v-if="role == 'owner' && !ud.employment.hourlyEmployment">
              <div class="row">
                 <ve-col xs12 md6>
                  <ve-label required :label="__('vacation')"></ve-label>
                  <ve-radio
                    v-model="ud.vacation"
                    inline
                    :options="{
                          		one: { label: __('modelVacation') },
                          		two: { label: __('vacation') }
                          	}">
                  ></ve-radio>
                </ve-col>
              </div>
            </ve-card>
            <ve-card :headline="__('title5')"  v-if="role == 'owner' && !ud.employment.hourlyEmployment">
              <div class="row">
                <ve-col xs12 md6>
                  <ve-label required :label="__('salary')"></ve-label>
                  <ve-input
                    v-model="ud.salary"
                    rules="required"
                    blurvalidate
                  ></ve-input>
                </ve-col>
                <ve-col xs12 md6>
                  <label class="field_title required">{{__("revisonYear")}}</label>
                  <ve-input
                    v-model="ud.revisonYear"
                    rules="required|number|max:2222"
                    mask="####"
                    blurvalidate
                  ></ve-input>
                </ve-col>
              </div>
            </ve-card>
            <ve-card :headline="__('title5')"  v-if="role == 'owner' && ud.employment.hourlyEmployment">
              <div class="row">
                <ve-col xs12 md6>
                  <ve-label required :label="__('salaryHour')"></ve-label>
                  <ve-input
                    v-model="ud.salaryHour"
                    rules="required"
                    blurvalidate
                  ></ve-input>
                </ve-col>
              </div>
            </ve-card>

            <ve-card :headline="__('title7')" v-if="role == 'editor'">
              <div class="row">
                <ve-col xs12 md4>
                  <ve-label required :label="__('bank')"></ve-label>
                  <ve-select
                    v-if="role == 'editor'"
                    class="dropdownBackground"
                    type="text"
                    v-model="ud.bank"
                    :searchable="true"
                    @on-select="$emit('update:bank', ud.bank), changePattern()"
                    :options="this.bankList"
                    rules="required"
                  ></ve-select>
                </ve-col>
                <ve-col xs12 md3>
                  <ve-label required :label="__('clearing')"></ve-label>
                  <ve-input
                  v-if="role == 'editor'"
                    v-model="ud.clearNo"
                    :placeholder="
                      this.ud.bank
                        ? this.bankListJSON[this.ud.bank].clearNoFormat
                        : ''
                    "
                    @input="$emit('update:ud.clearNo', ud.clearNo)"
                    type="number"
                    pattern="required"
                  ></ve-input>
                  <!-- <span style="color:#F77" v-if="this.bank && !this.clearNo">{{this.bankListJSON[this.bank].clearNoFormat}}</span> -->
                </ve-col>
                <ve-col xs12 md4>
                  <ve-label required :label="__('accountNumber')"></ve-label>
                     <ve-input
                     v-if="role == 'editor'"
                    v-model="ud.accountNo"
                    :placeholder="
                      this.ud.bank ? this.bankListJSON[this.ud.bank].accNoFormat : ''
                    "
                    @input="$emit('update:ud.accountNo', ud.accountNo)"
                    rules="required"
                  
                  ></ve-input>
                  <!-- <span style="color:#F77" v-if="this.bank && !this.accountNo">{{this.bankListJSON[this.bank].clearNoFormat}}</span> -->
                </ve-col>
              </div>
              <div class="row">
                <ve-col xs12 md6>
                  <ve-label required :label="__('taxTable')"></ve-label>
                  <ve-input
                    v-if="role == 'editor'"
                    v-model="ud.taxTable"
                    rules="required"
                    type="number"
                    blurvalidate
                  ></ve-input>
                </ve-col>
              </div>
            </ve-card>
            <!-- SIGN -->
            <ve-card :headline="__('sendTitle')">
              <!-- Submit message -->
              <div class="row">
                <h5>{{__('infoTitle')}}</h5>
                <p>{{__('infoText')}} <span v-show="ud.employment.hourlyEmployment">{{__('infoSalaryHour')}}</span></p>
              </div>
              <div class="row flex">
                <ve-form-group style="display: flex">
                  <ve-checkbox 
                    v-model="ud.tacInfo"
                    :options="{ 
                      'yes': { label: __('tacInfo') } 
                    }" 
                    :rules="role=='editor'?'required':''"
                    >
                  </ve-checkbox>
                  <ve-label v-if="role=='editor'" required></ve-label>
                </ve-form-group>
                <ve-col xs12 class="flex-right">
                  <div
                    v-if="!canSubmit && !showLoader"
                    class="info disabled-info labelRequired"
                  >
                    {{ __("requiredInfo") }}
                  </div>
                </ve-col>
              </div>

              <!-- Buttons -->
              <div class="row space-between">
                <!-- DISPLAY PDF -->
                <div>
                  <button
                    v-show="role === 'owner'"
                    type="button"
                    class="btn btn-rounded btn-primary"
                    @click="displayPDF = !displayPDF"
                    :disabled="loading"
                  >
                    {{ __("previewPDF") }}
                  </button>
                </div>

                <!-- SUBMIT -->
                <div id="submitBtn">
                  <button
                    type="button"
                    class="btn btn-rounded btn-primary"
                    @click="preSubmit"
                    :disabled="!canSubmit"
                  >
                    <span v-if="role === 'owner'">
                      {{ __("sendButton") }}
                    </span>
                      <span v-else>
                        {{ __("signButton") }}
                      </span>
                  </button>
                </div>
              </div>
            </ve-card>
          </form>
        </div>
      </div>

      <!-- LOADING -->
      <div v-show="loading || showLoader">
        <div class="loader-background"></div>
        <img
          class="loader"
          src="//app.verified.eu/lib/iframes/common/assets/loader64.gif"
        />
      </div>
    </div>

    <!-- PDF PREVIEW -->
    <transition name="fade" mode="out-in">
      <PdfPreview
        v-show="displayPDF && !loading"
        v-on:close="displayPDF = false"
        :data="this"
      />
    </transition>

    <!-- GENERATE PDF -->
    <ve-html-to-pdf
      style="display:none"
      ref="htmlToPDF"
      :copyDataFrom="this"
    ></ve-html-to-pdf>
  </div>
</template>

<style>
.inline-input {
  display: inline;
}

.inline-input > input {
  display: inline;
  width: 100px;
}
#cover {
  width: 100%;
  overflow: scroll;
  height: 100%;
  position: absolute;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}
</style>

<script>
export { default } from "./script.js";
</script>
